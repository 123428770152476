.ai-tabs{
  .ai-tab-list{
    list-style: none;
    padding: 0;
    .ai-tab{
      display: inline-block;
      width: 250px;
      padding: 1rem 2rem;
      border-bottom: 1px solid $cyan;
      margin-right: .5rem;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      border-radius: 15px 15px 0 0 ;
      z-index: 2;
      &:hover{
        &:after{
          transform: translateY(80%);
        }
      }
      &:after{
        content: '';
        width: 200%;
        height: 200%;
        background: $cyan;
        position: absolute;
        border-radius: 100%;
        left: -50%;
        z-index: -1;
        top: -200%;
        transition: transform 1.5s ease;
      }
      &.react-tabs__tab--selected{
        background: $cyan;
        .ai-tab-heading{
          color: $black;
        }
        .ai-tab-text{
          color: $black;
        }
      }
      .ai-tab-heading{
        display: block;
        color: $white;
      }
      .ai-tab-text{
        font-size: 12px;
        color: $white;
      }
    }
  }
  .ai-tab-content{
    background-color: $white;
    border-radius: 0 0 15px 15px;
    img{
      max-width: 100%;
      border-radius: 15px;
    }
    .ai-tab-content-title{
      color: $green;
      font-weight: 600;
      padding-bottom: 1rem;
    }
    .ai-tab-content-subtitle{
      font-size: 22px;
      font-weight: 600;
      padding-bottom: 1rem;
    }
    &.react-tabs__tab-panel--selected{
      padding: 2rem;
    }
  }
}