.ai-page-header{
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-size: cover;
  background-position: center center;
  .ai-page-title{
    height: auto;
    overflow: hidden;
  }
  .ai-page-subtitle{
    height: auto;
    overflow: hidden;
  }
  h1{
    color: $green;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
    span.ai-word-container{
      overflow: hidden;
      display: inline-block;
      line-height: 20px;
      span{
        display: inline-block
      }
    }
  }
  h2{
    color: $white;
    font-size: 52px;
    font-weight: 600;
    max-width: 70%;
    margin: 0 auto;
    span.ai-word-container{
      overflow: hidden;
      display: inline-block;
      line-height: 70px;
      vertical-align: top;
      span{
        display: inline-block
      }
    }
  }
  // Header Type 3 - Header With Offset Image
  &.header-type-3{
    background: $grey;
    .ai-header-image{
      position: relative;
      margin: 0 auto;
      padding: 0;
      img{
        position: absolute;
        padding: 0;
        border-radius: 10px;
        width: 80%;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
  &.header-type-2{
    text-align: left;
    position: relative;
    z-index: 2;
    &:after{
      content: "";
      background: rgba($blue, 0.7);
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: -1;
    }
    .ai-header-content{
      max-width: 80%;
      margin: 0 auto;
      .ai-page-title{}
      h1{
        font-size: 52px;
        color: $white;
        font-weight: 600;
        padding: 0;
        margin-bottom: 3rem;
        span.ai-word-container{
          overflow: hidden;
          display: inline-block;
          line-height: 72px;
          span{
            display: inline-block
          }
        }
      }
      span{
        color: $white;
        display: inline-block;
        width: auto;
        &.ai-post-type{
          background: $primary-cyan;
          font-size: 14px;
          color: $blue;
          padding: .5rem 1rem;
          border-radius: 0 15px 15px 0;
          margin-bottom: 2rem;
        }
      }
      .ai-project-details{
        display: inline-flex;
        align-items: center;
        overflow: hidden;
        span{
          font-size: 14px;
        }
        i{
          font-size: 24px;
          color: $white;
          margin-right: .5rem;
        }
        span.ai-word-container{
          overflow: hidden;
          display: inline-block;
          line-height: 18px;
          vertical-align: top;
          span{
            display: inline-block
          }
        }
      }
    }
  }
}